import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateOrAny } from "react-redux";
import { ClinicianApi } from "../../types/clinician-api";
import { Consult } from "../../types/consult-api";
import { Encounter, Episode, Message } from "../../types/emr-api";

export interface UIState {
    isDoseSpotOpen: boolean;
    [key: string]: any; // Allows additional dynamic state values
}

export interface CurrentConsultState {
    currentConsult?: Consult;
    currentEpisode?: Episode;
    currentEncounters?: Encounter[];
    newEncounter?: Encounter;
    currentMessages?: Message[];
    uiStates: UIState;
}

export const currentConsultSlice = createSlice({
    name: "currentConsult",
    initialState: {
        currentConsult: undefined,
        currentEpisode: undefined,
        currentEncounters: undefined,
        newEncounter: undefined,
        currentMessages: undefined,
        uiStates: {
            isDoseSpotOpen: false,
        },
    } as CurrentConsultState,
    reducers: {
        setCurrentConsultAction: (state, setConsultAction) => {
            state.currentConsult = setConsultAction.payload as Consult;
        },
        setCurrentEpisodeAction: (state, setEpisodeAction) => {
            state.currentEpisode = setEpisodeAction.payload as Episode;
        },
        setCurrentEncountersAction: (state, setEncountersAction) => {
            state.currentEncounters = setEncountersAction.payload as Encounter[];
        },
        setNewEncounterAction: (state, setEncounterAction) => {
            state.newEncounter = setEncounterAction.payload as Encounter;
        },
        setCurrentMessagesAction: (state, setMessagesAction) => {
            state.currentMessages = setMessagesAction.payload as Message[];
        },
        addToCurrentMessagesAction: (state, addMessageAction) => {
            (state.currentMessages as Message[]).push(addMessageAction.payload as Message);
        },
        setUIStateAction: (state, action: PayloadAction<{ key: string; value: any }>) => {
            state.uiStates[action.payload.key] = action.payload.value;
        },
    },
});

export const selectCurrentConsult = (state: RootStateOrAny) => state?.currentConsult?.currentConsult;
export const selectCurrentEpisode = (state: RootStateOrAny) => state?.currentConsult?.currentEpisode;
export const selectCurrentEncounters = (state: RootStateOrAny) => state?.currentConsult?.currentEncounters;
export const selectNewEncounter = (state: RootStateOrAny) => state?.currentConsult?.newEncounter;
export const selectCurrentMessages = (state: RootStateOrAny) => state?.currentConsult?.currentMessages;
export const selectUIState = (key: string) => (state: RootStateOrAny) => state?.uiStates?.[key];

export const {
    setCurrentConsultAction,
    setCurrentEpisodeAction,
    setCurrentEncountersAction,
    setNewEncounterAction,
    setCurrentMessagesAction,
    addToCurrentMessagesAction,
    setUIStateAction,
} = currentConsultSlice.actions;

export default currentConsultSlice.reducer;

export const updateInCallStatusAndPatientContact = createAsyncThunk(
    "consult/updateInCallStatusAndPatientContact",
    async (consult: Consult, { dispatch, rejectWithValue }) => {
        const client = new ClinicianApi();
        try {
            const response = await client.consult.consultInCallUpdate(consult.guid, consult);
            const newConsult = { ...consult, patientContacted: true };
            dispatch(setCurrentConsultAction(newConsult));
            return response.data;
        } catch (error) {
            const errorMessage = (error as any).response.data.detail;
            return rejectWithValue(errorMessage);
        }
    }
);
