import { useEffect, useState } from "react";
import { useSession } from "../functions/useSession";
import { ClinicianApi } from "../types/clinician-api";
import { WebPushSubscriptionEndpointTypeEnum } from "../types/consult-api";

const vapidPublicKey = "BEkcGb9OskcARagRpY884zH0v8DkgWiq0MKAi4cVP_lKW4dr8ZS1CqoQmcYaY6Dt2Uf2Z1eDt2nPFRYKN4idPuQ";

async function getSubscription() {
    if ("serviceWorker" in navigator && "PushManager" in window) {
        try {
            const registration = await navigator.serviceWorker.ready;
            return await registration.pushManager.getSubscription(); // Returns true if subscribed, false otherwise
        } catch (error) {
            console.error("Error checking service worker registration and subscription:", error);
            return null; // Return false in case of an error
        }
    } else {
        console.warn("Service workers or push notifications are not supported in this browser.");
        return null; // Not supported
    }
}

const PushNotificationManager = () => {
    const [session] = useSession();
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        const checkServiceWorker = async () => {
            const isRegistered = (await getSubscription()) !== null;
            setIsRegistered(isRegistered);
        };
        checkServiceWorker();
    }, []);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        //if it is already registered the param is not needed, we allow to use push notifications
        if (!session || (!isRegistered && urlSearchParams.get("pushNotificationSub")?.toLowerCase() !== "true")) return;

        const urlBase64ToUint8Array = (base64String: any) => {
            const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
            const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        };

        const subscribeUserToPush = async (clinicianGuid: string) => {
            try {
                const existingSubscription = await getSubscription();
                if (existingSubscription) {
                    console.log("Already subscribed:", existingSubscription);
                    await sendSubscriptionToBackend(existingSubscription, clinicianGuid);
                    return;
                }

                const applicationServerKey = urlBase64ToUint8Array(vapidPublicKey);

                const registration = await navigator.serviceWorker.ready;
                const newSubscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey,
                });

                console.log("New subscription:", newSubscription);
                await sendSubscriptionToBackend(newSubscription, clinicianGuid);
            } catch (error) {
                console.error("Failed to subscribe user:", error);
            }
        };

        const requestNotificationPermission = async (clinicianGuid: string) => {
            if ("Notification" in window) {
                const permission = await Notification.requestPermission();
                if (permission === "granted") {
                    await subscribeUserToPush(clinicianGuid);
                } else {
                    console.warn("Notification permission not granted.");
                }
            } else {
                console.warn("Notifications are not supported in this browser.");
            }
        };

        const clinicianGuid = (session as any)?.user.clinician.guid;
        if ("serviceWorker" in navigator && "PushManager" in window && clinicianGuid) {
            requestNotificationPermission(clinicianGuid);
        } else {
            console.warn("Push messaging is not supported in this browser.");
        }
    }, [session, isRegistered]);

    return null;
};

export default PushNotificationManager;

// Function to send the subscription data to your backend
const sendSubscriptionToBackend = async (subscription: { [key: string]: any }, clinicianGuid: string) => {
    console.log("Subscription JSON:", subscription.toString());
    const client = new ClinicianApi();
    client.clinician
        .clinicianSubscribeToPushCreate(clinicianGuid, {
            subscription: subscription,
            endpointType: WebPushSubscriptionEndpointTypeEnum.Browser,
        })
        .catch((reason) => console.log(reason));
};
