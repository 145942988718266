import axios, { AxiosError } from "axios";
import { logger } from "../logger/logger";
import { Feature } from "../types/pages/feature";
import helpers from "./helpers";

export async function getFeatureFlags(ctx: any): Promise<Feature[]> {
    const features = [];
    try {
        const waffleUrl = `${process.env.CONSULT_SERVICE_URL}/_waffle/waffle.json`;
        const response = await axios.get(waffleUrl, { headers: helpers.getAuthorization(ctx) });
        const flags = response.data.flags.map((f: any[]) => ({
            name: f[0],
            enabled: f[1],
        }));
        features.push(...flags);
        return features;
    } catch (err: AxiosError | any) {
        logger.error(err.message);
        return features;
    }
}

export default getFeatureFlags;
