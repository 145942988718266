import cookie from "cookie";
import { Provider as NextProvider } from "next-auth/client";
import type { AppProps } from "next/app";
import App from "next/app";
import Head from "next/head";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { FeatureToggle } from "../components/FeatureToggle";
import PushNotificationManager from "../components/PushNotificationManager";
import ServiceWorkerRegistration from "../components/ServiceWorkerRegistration";
import { getFeatureFlags } from "../functions/getFeatureFlags";
import "../public/scripts/rum";
import store from "../redux/stores/store";
import "../styles/globals.css";
import "../styles/theme.css";
import { Feature } from "../types/pages/feature";

const queryClient = new QueryClient();

function MyApp(props: AppProps) {
    const { Component, pageProps } = props;
    const { session } = pageProps;
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        // Check if the incoming features array is not empty
        if (pageProps.features && pageProps.features.length > 0) {
            // Update local state only if features is not empty
            setFeatures(pageProps.features);
        }
        // Intentionally omitting setFeatures from dependency array to avoid updates when features is empty
    }, [pageProps.features]); // Depend on pageProps.features for changes

    return (
        <NextProvider session={session}>
            <ServiceWorkerRegistration />
            <PushNotificationManager />
            <ReduxProvider store={store}>
                <QueryClientProvider client={queryClient}>
                    <Head>
                        <title>Clinician Portal - SteadyMD</title>
                        <meta property="og:title" content="SteadyMD - Clinician Portal" key="title" />
                    </Head>
                    <FeatureToggle features={features}>
                        {/*<Socket />*/}
                        <Component {...pageProps} />
                    </FeatureToggle>
                </QueryClientProvider>
            </ReduxProvider>
        </NextProvider>
    );
}

MyApp.getInitialProps = async function (appContext: any) {
    // Note: "cookie" should be imported here otherwise when imported from a function it causes a React client side exception in Dev Camps
    let authorizationHeader = {};
    let jwt;
    let features: Feature[] = [];
    const appProps = await App.getInitialProps(appContext);
    if (appContext?.ctx?.req?.headers?.cookie) {
        const cookies = cookie.parse(appContext.ctx.req.headers.cookie);
        jwt = cookies["__smd-clinician-portal.session-token"];
        if (jwt) {
            authorizationHeader = { Authorization: `Bearer ${jwt}` };
        }
        features = await getFeatureFlags(appContext.ctx.req);
    }
    return {
        pageProps: {
            ...appProps.pageProps,
            authorizationHeader,
            jwt,
            features,
        },
    };
};

export default MyApp;
