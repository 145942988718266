import { useEffect } from "react";

const ServiceWorkerRegistration = () => {
    useEffect(() => {
        if (typeof window !== "undefined" && "serviceWorker" in navigator) {
            const registerServiceWorker = async () => {
                try {
                    const registration = await navigator.serviceWorker.register("/service-worker.js");

                    // Check for updates to the service worker
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.onstatechange = () => {
                                if (installingWorker.state === "installed") {
                                    if (navigator.serviceWorker.controller) {
                                        // New update available
                                        console.log("New content is available; please refresh.");
                                    } else {
                                        // Content is cached for offline use
                                        console.log("Content is cached for offline use.");
                                    }
                                }
                            };
                        }
                    };

                    console.log("Service Worker registered with scope:", registration.scope);
                } catch (error) {
                    console.error("Service Worker registration failed:", error);
                }
            };

            window.addEventListener("load", registerServiceWorker);
        }
    }, []);

    return null;
};

export default ServiceWorkerRegistration;
